<template>
  <div class="tw-w-full lg:tw-w-1/3 tw-mb-4 md:tw-mb-0 tw-px-4">
    <div
      class="tw-p-8 tw-pb-4 tw-border ta-border-gray-104 tw-bg-gray-100 tw-rounded-lg"
    >
      <h2 class="tw-mb-6 tw-text-gray-800">Employees</h2>

      <p class="tw-mb-4 tw-text-gray-700">
        To import employees please download this Excel (.xlsx) template, fill in
        the details of your employees and then upload.
      </p>

      <form class="tw-mt-2" method="POST">
        <div class="tw-flex tw-items-center tw-flex-wrap tw--mx-2">
          <SpinnerButton
            :disabled="uploading"
            :loading="uploading"
            :spinner-only="true"
            type="button"
            class="tw-mb-4 tw-mx-1 tw-cursor-pointer"
            data-cy="btn-bulk-upload-employments"
            @click="$refs.uploadInput.click()"
          >
            Upload using Template
            <input
              ref="uploadInput"
              :disabled="uploading"
              type="file"
              hidden
              class="hidden"
              data-cy="bulk-upload-employments-input"
              @change="importEmployments"
            />
          </SpinnerButton>

          <SpinnerButton
            :disabled="downloading"
            :loading="downloading"
            :spinner-only="true"
            :spinner-classes="['tw-h-2 tw-w-2 tw-text-blue-500']"
            as="link"
            class="tw-mb-4 tw-mx-1 tw-px-6 tw-py-3"
            type="button"
            @click="downloadTemplate"
          >
            Download Template
          </SpinnerButton>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import SpinnerButton from '@/components/SpinnerButton'
import ValidatesForm from '@/mixins/ValidatesForm'
import EmploymentsImport from '@/api/imports/EmploymentsImport'

export default {
  name: 'EmploymentsImport',

  components: { SpinnerButton },

  mixins: [ValidatesForm],

  data: () => ({
    uploading: false,
    downloading: false,
  }),

  methods: {
    async downloadTemplate() {
      this.downloading = true

      try {
        await EmploymentsImport.downloadTemplate(
          this.$route.query,
          'import_employment_template.xlsx'
        )
      } catch ({ response }) {
        this.validateFromResponse(response, false)
      }

      this.downloading = false
    },

    async importEmployments() {
      this.uploading = true

      try {
        const formData = new FormData()
        formData.append('company_id', this.activeCompany.id)
        formData.append('file', this.$refs.uploadInput.files[0])

        await EmploymentsImport.import(formData)

        this.success(
          'Your upload has been submitted, you will receive a notification when it is completed.'
        )
      } catch ({ response }) {
        if (response.status === 422) {
          this.validationErrors(response.data.errors, { duration: 7000 })
        } else {
          this.error(response.data.message)
        }
      }

      this.$nextTick(() => {
        this.$refs.uploadInput.value = ''
      })

      this.uploading = false
    },
  },
}
</script>
