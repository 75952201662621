<template>
  <div>
    <div class="card card-container">
      <div class="tw-py-10">
        <div class="tw-flex tw-items-center tw-justify-between tw-flex-wrap">
          <div>
            <h2
              class="page-title tw-flex tw-items-center"
              data-cy="leave-types-title"
            >
              <span>Leave Types</span>
              <ExtraInfo icon="question" class="tw--mt-2">
                <div class="tw-p-4 tw-w-48">
                  There are a variety of reasons why your staff may need to take
                  some time off from work. You may configure as many types of
                  leave as required.
                  <div>
                    <BaseLink
                      class="tw-font-semibold"
                      :href="leaveTypesDocumentationUrl"
                    >
                      More info
                    </BaseLink>
                  </div>
                </div>
              </ExtraInfo>
            </h2>
          </div>
          <div>
            <SpinnerButton
              data-cy="btn-add-leavetypes"
              type="button"
              @click="create"
            >
              Add Leave Type
            </SpinnerButton>
          </div>
        </div>
      </div>

      <div v-scroll-hint class="table-responsive settings-table">
        <table class="ld-table ld-table-clickable">
          <thead>
            <tr>
              <th>Leave Types</th>
              <th>Colour</th>
              <th>Description</th>
              <th style="width: 100px" />
            </tr>
          </thead>
          <tbody>
            <template v-if="leaveTypes.length">
              <tr
                v-for="leaveType in leaveTypes"
                :key="leaveType.id"
                @click.stop="edit(leaveType)"
              >
                <td>{{ leaveType.name }}</td>
                <td>
                  <SvgIcon
                    :style="{ color: leaveType.colour }"
                    name="bolt"
                    class="tw-h-6 tw-w-6"
                  />
                </td>
                <td v-text="leaveType.description" />
                <td>
                  <div class="tw-flex tw-items-center tw-justify-end">
                    <button
                      :disabled="leaveType.is_default === true"
                      class="btn tw-border tw-border-gray-400 hover:tw-bg-gray-300 tw-ml-3 tw-px-3 tw-py-0 tw-h-8 tw-text-gray-700"
                      data-cy="lvMarkAsDefault-btn"
                      @click.stop="markAsDefault(leaveType)"
                    >
                      Mark as default
                    </button>
                    <button
                      class="btn btn-icon tw-border tw-border-gray-400 hover:tw-bg-gray-300 tw-ml-3"
                      title="Edit"
                      data-cy="lvEdit-btn"
                      @click.stop="edit(leaveType)"
                    >
                      <div class="tw-flex tw-items-center tw-justify-center">
                        <SvgIcon
                          name="edit-pencil"
                          class="svg-icon tw-w-3 tw-h-3 tw-text-gray-700"
                        />
                      </div>
                    </button>
                    <DisabledElementWithTitle
                      :title="
                        leaveType.is_default
                          ? 'You can not delete default leave type'
                          : 'Delete'
                      "
                    >
                      <button
                        :disabled="leaveType.is_default"
                        class="btn btn-icon tw-border tw-bg-red-500 hover:tw-bg-red-500 tw-border-red-500 tw-ml-3"
                        data-cy="lvDelete-btn"
                        @click.stop="destroy(leaveType)"
                      >
                        <div class="tw-flex tw-items-center tw-justify-center">
                          <SvgIcon
                            name="trash"
                            class="svg-icon tw-w-3 tw-h-3 tw-text-white"
                          />
                        </div>
                      </button>
                    </DisabledElementWithTitle>
                  </div>
                </td>
              </tr>
            </template>
            <tr v-else>
              <td class="tw-bg-white" colspan="5"><NoData /></td>
            </tr>
          </tbody>
        </table>
      </div>

      <Modal
        id="leave-types-form"
        :classes="[
          'tw-shadow-md',
          'tw-bg-white',
          'tw-rounded-lg',
          'modal-overflow-visible',
        ]"
        :max-width="530"
        name="leave-types-form"
        width="95%"
        height="auto"
        adaptive
        scrollable
      >
        <div class="modal-header">
          <div class="tw-flex tw-justify-between">
            <div>
              <p class="modal-title">
                {{ editing ? 'Edit' : 'New' }} Leave Type
              </p>
            </div>
            <div data-cy="edit-leave-close">
              <button
                class="modal-close"
                @click="$modal.hide('leave-types-form')"
              >
                <SvgIcon name="close" class="tw-w-4 tw-h-4" />
              </button>
            </div>
          </div>
        </div>

        <div class="tw-mt-3 tw-p-3">
          <form class="tw-w-full" @submit.prevent="submit">
            <div class="form-group">
              <div class="tw-w-full tw-px-3">
                <label class="form-label" for="name">
                  Leave Name <span class="required-field">&#42;</span>
                </label>
                <input
                  id="name"
                  v-model="name"
                  v-validate="'required|max:50'"
                  v-focus
                  data-vv-name="name"
                  data-vv-as="leave name"
                  data-cy="leave-name"
                  class="form-control"
                  type="text"
                  autocomplete="off"
                />
                <p
                  v-show="errors.has('name')"
                  data-cy="leave-name-error"
                  class="tw-mt-1 tw-text-red-700 tw-text-sm"
                >
                  {{ errors.first('name') }}
                </p>
              </div>
            </div>

            <div class="form-group">
              <div class="tw-w-full tw-px-3">
                <label class="form-label" for="description">
                  Leave Description
                </label>
                <input
                  id="description"
                  v-model="description"
                  v-validate="'max:100'"
                  data-vv-name="description"
                  data-vv-as="leave description"
                  data-cy="leave-description"
                  class="form-control"
                  type="text"
                  autocomplete="off"
                />
                <p
                  v-show="errors.has('description')"
                  data-cy="leave-description-error"
                  class="tw-mt-1 tw-text-red-700 tw-text-sm"
                >
                  {{ errors.first('description') }}
                </p>
              </div>
            </div>

            <div class="form-group">
              <div class="tw-w-full tw-px-3">
                <label class="form-label" for="colour">
                  Display Colour <span class="required-field">&#42;</span>
                </label>
                <CompactPicker
                  id="colour"
                  v-validate="'required'"
                  :value="colour"
                  :palette="colorBucket"
                  data-vv-name="colour"
                  data-vv-as="display colour"
                  data-cy="leave-colour"
                  @input="item => (colour = item.hex)"
                />
                <p
                  v-show="errors.has('colour')"
                  class="tw-mt-1 tw-text-red-700 tw-text-sm"
                >
                  {{ errors.first('colour') }}
                </p>
              </div>
            </div>

            <div class="form-group">
              <div class="tw-w-full tw-px-3">
                <label class="form-label" for="allowance_type_id">
                  Deduct From Allowance
                </label>
                <div class="tw-relative">
                  <VSelect
                    id="allowance_type_id"
                    v-model="selectedAllowanceType"
                    :options="selectableAllowances"
                    :multiple="false"
                    :searchable="false"
                    :show-labels="false"
                    :allow-empty="false"
                    :max-height="180"
                    track-by="id"
                    label="name"
                    placeholder=""
                    data-vv-name="allowance_type_id"
                    data-vv-as="deduct from allowance"
                    data-cy="deduct-from-allowance"
                  >
                  </VSelect>
                </div>
              </div>
            </div>

            <div class="form-group">
              <div class="tw-w-full tw-px-3">
                <label class="form-label tw-mb-3" for="allowance_type_id">
                  Options
                </label>

                <div class="tw-mb-3 tw-flex tw-items-center tw-leading-none">
                  <ToggleButton
                    v-model="is_private"
                    :labels="true"
                    color="#1da1f2"
                    data-cy="hidden-leave-type"
                    @change="handleIsPrivateChange($event.value)"
                  />
                  <label class="tw-ml-2 tw-select-none tw-flex tw-items-center">
                    <span
                      >Hidden on the wall chart (except for approvers and admin
                      users)</span
                    >
                  </label>
                </div>

                <div class="tw-mb-3 tw-flex tw-items-center tw-leading-none">
                  <ToggleButton
                    v-model="only_admin"
                    :labels="true"
                    color="#1da1f2"
                    data-cy="admin-request"
                  />
                  <label class="tw-ml-2 tw-select-none tw-flex tw-items-center">
                    <span>Only admin users can request this</span>
                  </label>
                </div>

                <div class="tw-mb-3 tw-flex tw-items-center tw-leading-none">
                  <ToggleButton
                    v-model="notRequireApproval"
                    :labels="true"
                    color="#1da1f2"
                    data-cy="request-approval"
                  />
                  <label class="tw-ml-2 tw-select-none tw-flex tw-items-center">
                    <span>Requests <u>do not</u> require approval</span>
                  </label>
                </div>

                <div class="tw-mb-3 tw-flex tw-items-center tw-leading-none">
                  <ToggleButton
                    v-model="is_reason_public"
                    :labels="true"
                    color="#1da1f2"
                    data-cy="is-reason-public"
                  />
                  <label class="tw-ml-2 tw-select-none tw-flex tw-items-center">
                    <span
                      >The reason for this request should be shown publicly in
                      the Wall Chart</span
                    >
                    <ExtraInfo icon="question">
                      <div class="tw-p-4 tw-w-48">
                        <p>
                          If someone enters a reason for their leave request,
                          would you like it to be made
                          <span class="tw-font-semibold">public</span> for
                          everyone to see?
                        </p>
                        <p class="tw-mt-2">
                          If they mark their leave request as
                          <span class="tw-font-semibold">private</span>, the
                          reason will always be kept private, regardless of this
                          setting.
                        </p>
                      </div>
                    </ExtraInfo>
                  </label>
                </div>

                <div class="tw-mb-3 tw-flex tw-items-center tw-leading-none">
                  <ToggleButton
                    v-model="ignoreFromConflicts"
                    :labels="true"
                    :disabled="is_private"
                    color="#1da1f2"
                    data-cy="ignore-leave"
                    sync
                  />
                  <label class="tw-ml-2 tw-select-none tw-flex tw-items-center">
                    <span
                      >This should be <u>ignored</u> when calculating conflicts
                      and leave limits</span
                    >
                    <ExtraInfo icon="question">
                      <div class="tw-p-4 tw-w-48">
                        <p>
                          Should requests for this leave type be shown as
                          conflicts with other requests or impact the number of
                          people allowed on leave at the same time?
                          <a
                            class="btn-link"
                            href="https://help.leavedates.com/hc/en-us/articles/360012118560-Limit-how-many-people-can-take-the-same-day-off"
                            target="_blank"
                            >More info</a
                          >
                        </p>
                      </div>
                    </ExtraInfo>
                  </label>
                </div>
              </div>
            </div>

            <div class="tw-flex tw-flex-wrap tw-mb-3">
              <div class="tw-w-full tw-px-3">
                <div class="tw-flex tw-justify-end">
                  <button
                    v-if="!editing"
                    class="btn btn-borderless btn-link"
                    type="button"
                    @click="reset"
                  >
                    Clear
                  </button>

                  <SpinnerButton
                    :disabled="!valid || loading"
                    :loading="loading"
                    :spinner-only="true"
                    data-cy="submit-add-leave-type"
                    type="submit"
                  >
                    Save
                  </SpinnerButton>
                </div>
              </div>
            </div>
          </form>
        </div>
      </Modal>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import { Compact } from 'vue-color'
import VSelect from 'vue-multiselect'
import NoData from '@/components/NoData'
import { ToggleButton } from 'vue-js-toggle-button'
import ExtraInfo from '@/components/ExtraInfo'
import ValidatesForm from '@/mixins/ValidatesForm'
import LeaveTypesShortcuts from '@/mixins/page-shortcuts/settings/LeaveTypesShortcuts'
import DisabledElementWithTitle from '@/components/DisabledElementWithTitle'
import SpinnerButton from '@/components/SpinnerButton'
import BaseLink from '@/components/BaseLink'
import documentationUrls from '@/documentations/documentation-urls'
import { find, orderBy } from 'lodash-es'

export default {
  name: 'LeaveTypes',

  components: {
    NoData,
    VSelect,
    ExtraInfo,
    ToggleButton,
    DisabledElementWithTitle,
    SpinnerButton,
    BaseLink,
    CompactPicker: Compact,
  },

  mixins: [ValidatesForm, LeaveTypesShortcuts],

  data: () => ({
    id: null,
    name: '',
    description: '',
    allowance_type_id: null,
    is_reason_public: '',
    allowances: [],
    leaveTypes: [],
    display_icon: null,
    colour: '',
    approval: true,
    only_admin: false,
    is_private: false,
    accounts_to_conflicts: true,
    editing: false,
    loading: false,
    colorBucket: [
      '#F05451',
      '#ff9f43',
      '#fcf600',
      '#1cd1a1',
      '#00a3a4',
      '#4ddbfb',
      '#35aaf0',
      '#1561bd',
      '#5f27cd',
      '#9f7de1',
      '#f183da',
      '#576574',
      '#f8baba',
      '#ffd9b4',
      '#fefb99',
      '#d3f5d6',
      '#99dadb',
      '#b8f1fd',
      '#aeddf9',
      '#a1c0e5',
      '#bfa9eb',
      '#d9cbf3',
      '#fba7e9',
      '#bcc1c7',
    ],
  }),

  computed: {
    leaveTypesDocumentationUrl() {
      return documentationUrls.leaveTypeUrl
    },

    selectableAllowances() {
      return [
        {
          id: null,
          name: 'Does not deduct',
        },
        ...orderBy(this.allowances, 'name'),
      ]
    },

    selectedAllowanceType: {
      get() {
        return find(this.selectableAllowances, { id: this.allowance_type_id })
      },
      set(val) {
        this.allowance_type_id = val.id
      },
    },

    ignoreFromConflicts: {
      get() {
        return !this.accounts_to_conflicts
      },
      set(ignore) {
        this.accounts_to_conflicts = !ignore
      },
    },

    notRequireApproval: {
      get() {
        return !this.approval
      },
      set(notRequire) {
        this.approval = !notRequire
      },
    },
  },

  watch: {
    '$route.query.company': {
      immediate: true,
      handler(newVal, oldVal) {
        if (newVal === oldVal) return

        this.fetch()
        this.fetchAllowances()
      },
    },
  },

  methods: {
    ...mapActions('auth', ['fetchUser']),

    handleIsPrivateChange(isPrivate) {
      if (isPrivate) this.ignoreFromConflicts = true
    },

    async fetch() {
      try {
        const { data } = await this.$http.get('leave-types', {
          params: this.$route.query,
        })

        this.leaveTypes = data
      } catch ({ response }) {
        this.validateFromResponse(response, false)
      }
    },

    async fetchAllowances() {
      try {
        const { data } = await this.$http.get('allowance-types', {
          params: this.$route.query,
        })

        this.allowances = data
      } catch ({ response }) {
        this.validateFromResponse(response, false)
      }
    },

    async markAsDefault(leaveType) {
      await this.$http.post('/default-leave-types', {
        leave_type_id: leaveType.id,
        company_id: leaveType.company_id,
      })

      await Promise.all([this.fetch(), this.fetchUser()])

      this.success('Leave type marked as default successfully.')
    },

    edit(leaveType) {
      this.editing = true
      this.id = leaveType.id
      this.name = leaveType.name
      this.colour = leaveType.colour
      this.display_icon = leaveType.display_icon
      this.approval = leaveType.approval
      this.only_admin = leaveType.only_admin
      this.is_private = leaveType.is_private
      this.accounts_to_conflicts = leaveType.accounts_to_conflicts
      this.description = leaveType.description
      this.allowance_type_id = leaveType.allowance_type_id
      this.is_reason_public = leaveType.is_reason_public

      this.$modal.show('leave-types-form')
    },

    async destroy(leaveType) {
      const confirmed = await this.confirm(
        'Are you sure you want to delete?',
        'Confirm delete'
      )

      if (!confirmed) return

      try {
        await this.$http.delete(`leave-types/${leaveType.id}`, {
          data: { company_id: this.activeCompany.id },
        })

        this.fetch()

        this.success('Leave type deleted successfully.')
      } catch ({ response }) {
        if (response && this.isClientError(response.status)) {
          this.error(response.data.errors.message[0])

          return
        }

        this.validateFromResponse(response)
      }
    },

    create() {
      this.reset()

      this.editing = false

      this.$modal.show('leave-types-form')
    },

    async submit() {
      await this.validate()

      if (!this.valid) return

      try {
        this.loading = true

        const method = this.editing ? 'put' : 'post'
        const uri = this.editing ? `leave-types/${this.id}` : 'leave-types'

        await this.$http[method](uri, {
          name: this.name,
          colour: this.colour,
          display_icon: this.display_icon ?? this.findDisplayIcon(),
          approval: this.approval,
          only_admin: this.only_admin,
          is_private: this.is_private,
          accounts_to_conflicts: this.accounts_to_conflicts,
          description: this.description,
          is_reason_public: this.is_reason_public,
          allowance_type_id: this.allowance_type_id,
          company_id: this.activeCompany.id,
        })

        await Promise.all([this.fetch(), this.fetchUser()])

        this.success('Leave type saved successfully.')

        this.$modal.hide('leave-types-form')
        this.reset()
      } catch ({ response }) {
        this.validateFromResponse(response)
      }

      this.loading = false
    },

    reset() {
      this.$nextTick(() => {
        this.id = null
        this.name = ''
        this.description = ''
        this.allowance_type_id = ''
        this.display_icon = null
        this.colour = ''
        this.approval = true
        this.only_admin = false
        this.is_private = false
        this.loading = false
        this.editing = false
        this.is_reason_public = false
        this.accounts_to_conflicts = true
        this.$validator.reset()
      })
    },

    findDisplayIcon() {
      const displayIconMapper = {
        'Unpaid Leave': 'zap',
        'Jury Service': 'scales',
        'Purchased Leave': 'zap',
        'Public Holiday': 'balloon',
        'Sick Leave': 'health',
        'Study Leave / Training': 'academic',
        'Compassionate Leave': 'heart',
        'Dependant Leave': 'life-buoy',
        Other: 'zap',
        'Annual Leave': 'umbrella',
        'Maternity Leave': 'baby',
        'Paternity Leave': 'baby',
        TOIL: 'clock',
        'Casual leave': 'pizza',
        'Parental leave': 'baby',
        'Work from home': 'house',
        Vacation: 'umbrella',
        'Bereavement leave': 'face-sad',
        Holiday: 'umbrella',
        Training: 'academic',
        Birthday: 'cake',
        'Time Off In Lieu': 'clock',
        'Bank Holiday': 'balloon',
        'Working from home': 'house',
        'Medical Leave': 'health',
        'Birthday Leave': 'cake',
        'Vacation Leave': 'umbrella',
        'Emergency Leave': 'life-buoy',
        'Personal Leave': 'face-happy',
        WFH: 'house',
        'Family Responsibility Leave': 'heart',
        'Marriage Leave': 'ring',
        'Childcare leave': 'baby',
        'Maternity/Paternity Leave': 'baby',
        'Study Leave': 'academic',
        PTO: 'umbrella',
        'Day off': 'umbrella',
        'Time in lieu': 'clock',
        'JURY DUTY': 'scales',
        Sick: 'health',
        'Special Leave': 'stars',
        Maternity: 'baby',
        Bereavement: 'heart',
        'Paid Leave': 'umbrella',
        'Medical Appointment': 'health',
        'Business trip': 'briefcase',
        'Hospitalisation Leave': 'health',
        Overtime: 'clock',
        'Christmas Shutdown': 'stars',
        Late: 'cloud-raining',
        'Earned Leave': 'clock',
        Furlough: 'bonfire',
        'Hospitalization Leave': 'health',
        'Lieu Day': 'clock',
        'Carers Leave': 'heart',
        'Home Office': 'house',
        'Child Care Leave': 'heart',
        'Christmas Leave': 'stars',
        'Maternity/Paternity': 'baby',
        'Sick Day': 'health',
        Appointment: 'briefcase',
        'Leave Without Pay': 'zap',
        'Maternity / Paternity Leave': 'baby',
        Leave: 'umbrella',
        'Remote Work': 'house',
        'Paid Time Off': 'umbrella',
        'Public Holidays': 'balloon',
        Christmas: 'stars',
        'CHRISTMAS CLOSURE': 'stars',
        Paternity: 'baby',
        'Bank Holidays': 'balloon',
        Sickness: 'health',
        'Half Day': 'sunset',
        'Wedding Leave': 'ring',
        'Long Service Leave': 'beer',
        Holidays: 'umbrella',
        'Carry Over': 'backpack',
        'Day in Lieu': 'clock',
        'Replacement Leave': 'clock',
        'Office Closed': 'beer',
        'Paid Sick Leave': 'health',
        'Adoption leave': 'baby',
        Test: 'zap',
        RTT: 'bus',
        'Off in lieu': 'clock',
        lieu: 'clock',
        'Time off': 'umbrella',
        'Compensation Leave': 'clock',
        'CHRISTMAS BREAK': 'stars',
        Ferie: 'umbrella',
        'Non Working Day': 'cafe',
        'Out Of Office': 'house',
        'Unpaid Sick Leave': 'health',
        'Non-working day': 'cafe',
        'Family Leave': 'heart',
        Flexi: 'clock',
        'Lieu Time': 'clock',
        Férias: 'umbrella',
        'Time Off in Lieu (TOIL)': 'clock',
        'Compensatory leave': 'clock',
        'Other Leave': 'zap',
        'Birthday Day Off': 'cake',
        '*Public Holiday': 'balloon',
        'Christmas Shut Down': 'stars',
        'Business Travel': 'briefcase',
        'Personal Day': 'face-happy',
        FMLA: 'heart',
        'School Holidays': 'academic',
        'Force Majeure': 'cloud-raining',
        RDO: 'cafe',
        'Company Holiday': 'balloon',
        Urlaub: 'umbrella',
        Sabbatical: 'bonfire',
        Appointments: 'briefcase',
        Unavailable: 'bonfire',
        Personal: 'umbrella',
        Vacaciones: 'umbrella',
        'Off Day': 'umbrella',
        Covid: 'health',
        'Exam Leave': 'academic',
        'Sick Leave - Unpaid': 'health',
        'Unauthorised Absence': 'face-sad',
        'In Lieu': 'clock',
        Others: 'zap',
        Maladie: 'health',
        'Family responsibility': 'heart',
        'Certified Sick Leave': 'health',
        Medical: 'health',
        'Remote Working': 'house',
        'Working Remotely': 'house',
        Vakantie: 'umbrella',
        'Funeral Leave': 'heart',
        Feriado: 'health',
        'Examination Leave': 'academic',
        'Half Day Leave': 'sunset',
        'Doctors appointment': 'health',
        'Office Closure': 'stars',
        Emergency: 'life-buoy',
        Travelling: 'bus',
        'Company Closure': 'stars',
        'Christmas Holidays': 'stars',
        'Dependent Leave': 'heart',
        'Short Leave': 'zap',
        Marriage: 'ring',
        Lateness: 'face-sad',
        'Hospital appointment': 'health',
        'Duvet Day': 'beer',
        'Vacation Day': 'umbrella',
        'Concediu Medical': 'health',
        Unpaid: 'zap',
      }

      let displayIcon = displayIconMapper[this.name]

      return displayIcon ?? 'face-happy'
    },
  },
}
</script>
