<template>
  <div class="tw-w-full lg:tw-w-1/3 tw-mb-4 md:tw-mb-0 tw-px-4">
    <div
      class="tw-p-8 tw-pb-4 tw-border ta-border-gray-104 tw-bg-gray-100 tw-rounded-lg"
    >
      <h2 class="tw-mb-6 tw-text-gray-800">Leave</h2>

      <p class="tw-mb-4 tw-text-gray-700">
        To import leave records please download this Excel (.xlsx) template,
        fill in the details of the leave to import and then upload.
      </p>

      <form class="tw-mt-2" method="POST">
        <div class="tw-flex tw-items-center tw-flex-wrap tw--mx-2">
          <SpinnerButton
            :disabled="uploading"
            :loading="uploading"
            :spinner-only="true"
            type="button"
            class="tw-mb-4 tw-mx-1 tw-cursor-pointer"
            data-cy="bulk-upload-leaves"
            @click="browseLeaves"
          >
            Upload using Template
            <input
              ref="uploadInput"
              type="file"
              class="hidden"
              data-cy="bulk-upload-leaves-input"
              hidden
              @change="importLeaves"
            />
          </SpinnerButton>

          <SpinnerButton
            :disabled="downloading"
            :loading="downloading"
            :spinner-only="true"
            :spinner-classes="['tw-h-2 tw-w-2 tw-text-blue-500']"
            as="link"
            class="tw-mb-4 tw-mx-1 tw-px-6 tw-py-3"
            type="button"
            @click="downloadTemplate"
          >
            Download Template
          </SpinnerButton>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import FileSaver from 'file-saver'
import SpinnerButton from '@/components/SpinnerButton'
import Subscription from './../../mixins/Subscription'
import ValidatesForm from './../../mixins/ValidatesForm'

export default {
  name: 'LeaveImport',

  components: { SpinnerButton },

  mixins: [ValidatesForm, Subscription],

  data: () => ({
    uploading: false,
    downloading: false,
  }),

  methods: {
    async browseLeaves() {
      if (!this.hasProperSubscription) {
        const confirmed = await this.confirm(
          'You currently have more employees than allowed by your plan. Would you like to upgrade your plan?',
          'Exceeded plan limit'
        )

        if (!confirmed) return

        return this.$router.push({ name: 'billing' }, () => {})
      }

      this.$refs.uploadInput.click()
    },

    async downloadTemplate() {
      this.downloading = true

      try {
        const { data } = await this.$http.get(`import-leaves`, {
          params: this.$route.query,
          responseType: 'blob',
        })
        FileSaver.saveAs(new Blob([data]), 'import_leaves_template.xlsx')
      } catch ({ response }) {
        this.validateFromResponse(response, false)
      }

      this.downloading = false
    },

    async importLeaves() {
      this.uploading = true

      try {
        const formData = new FormData()
        formData.append('company_id', this.activeCompany.id)
        formData.append('file', this.$refs.uploadInput.files[0])

        await this.$http.post('import-leaves', formData, {
          'Content-Type': 'multipart/form-data',
        })

        this.success(
          'Your upload has been submitted, you will receive a notification when it is completed.'
        )
      } catch ({ response }) {
        this.validateFromResponse(response)
      }

      this.$nextTick(() => {
        this.$refs.uploadInput.value = ''
      })

      this.uploading = false
    },
  },
}
</script>
