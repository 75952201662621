<template>
  <div class="tw-w-full lg:tw-w-1/3 tw-mb-4 md:tw-mb-0 tw-px-4">
    <div
      class="tw-p-8 tw-pb-4 tw-border ta-border-gray-104 tw-bg-gray-100 tw-rounded-lg"
    >
      <h2 class="tw-mb-6 tw-text-gray-800">Allowances</h2>

      <p class="tw-mb-4 tw-text-gray-700">
        To import allowances please download this Excel (.xlsx) template, fill
        in the details of the allowances to import and then upload.
      </p>

      <form class="tw-mt-2" method="POST">
        <div class="tw-flex tw-items-center tw-flex-wrap tw--mx-2">
          <SpinnerButton
            :disabled="uploading"
            :loading="uploading"
            :spinner-only="true"
            class="tw-mb-4 tw-mx-1 tw-cursor-pointer"
            type="button"
            @click="$refs.uploadInput.click()"
          >
            Upload using Template
            <input
              ref="uploadInput"
              type="file"
              hidden
              class="hidden"
              data-cy="bulk-upload-allowances-input"
              @change="importEmploymentAllowances"
            />
          </SpinnerButton>

          <SpinnerButton
            :disabled="downloading"
            :loading="downloading"
            :spinner-only="true"
            :spinner-classes="['tw-h-2 tw-w-2 tw-text-blue-500']"
            as="link"
            class="tw-mb-4 tw-mx-1 tw-px-6 tw-py-3"
            type="button"
            @click="create"
          >
            Download Template
          </SpinnerButton>
        </div>
      </form>
    </div>

    <Modal
      :classes="[
        'tw-shadow-md',
        'tw-bg-white',
        'tw-rounded-lg',
        'modal-overflow-visible',
      ]"
      :max-width="480"
      name="allowances-modal"
      width="95%"
      height="auto"
      adaptive
      scrollable
    >
      <div class="modal-header">
        <div class="tw-flex tw-justify-between">
          <div>
            <p class="modal-title">
              Download Template
            </p>
          </div>
          <div data-cy="allowance-close">
            <button
              class="modal-close"
              @click="$modal.hide('allowances-modal')"
            >
              <SvgIcon name="close" class="tw-w-4 tw-h-4" />
            </button>
          </div>
        </div>
      </div>

      <div class="tw-mt-3 tw-p-3">
        <form class="tw-w-full">
          <div class="form-group">
            <div class="tw-w-full tw-px-3">
              <label class="form-label" for="calendar">
                Calendar <span class="required-field">&#42;</span>
              </label>
              <CalendarPicker
                id="calendar"
                v-model="selectedCalendar"
                :calendars="calendars"
              />
            </div>
          </div>

          <div class="tw-flex tw-flex-wrap tw-mb-3">
            <div class="tw-w-full tw-px-3">
              <div class="tw-flex tw-justify-end">
                <SpinnerButton
                  :disabled="loading"
                  type="button"
                  @click="downloadTemplate"
                >
                  Download
                </SpinnerButton>
              </div>
            </div>
          </div>
        </form>
      </div>
    </Modal>
  </div>
</template>

<script>
import FileSaver from 'file-saver'
import SpinnerButton from '@/components/SpinnerButton'
import FormatDate from '@/mixins/FormatDate'
import ValidatesForm from '@/mixins/ValidatesForm'
import moment from 'moment-timezone'

const CalendarPicker = () =>
  import('@/components/calendar-picker/CalendarPicker')

export default {
  name: 'AllowanceImport',

  components: {
    CalendarPicker,
    SpinnerButton,
  },

  mixins: [FormatDate, ValidatesForm],

  data: () => ({
    loading: false,
    uploading: false,
    downloading: false,
    calendars: [],
    selectedCalendar: null,
  }),

  computed: {
    formattedDate() {
      return (
        this.formatDateFromIsoToDayReadableShortDayNumberShortMonthNumberYearNumber(
          this.selectedCalendar.start_date,
          'utc'
        ) +
        '_' +
        this.formatDateFromIsoToDayReadableShortDayNumberShortMonthNumberYearNumber(
          this.selectedCalendar.end_date,
          'utc'
        )
      )
    },
  },

  created() {
    this.fetchCalendars().then(() => {
      this.selectedCalendar = this.defaultCalendar()
    })
  },

  methods: {
    defaultCalendar() {
      return (
        this.calendars.find(calendar => {
          return moment
            .utc(calendar.start_date)
            .isSame(moment().utc(true), 'year')
        }) || this.calendars[0]
      )
    },

    create() {
      this.$modal.show('allowances-modal')
    },

    async downloadTemplate() {
      this.$modal.hide('allowances-modal')
      this.downloading = true

      try {
        const { data } = await this.$http.get(`import-employment-allowances`, {
          params: {
            company_id: this.activeCompany.id,
            calendar_id: this.selectedCalendar.id,
          },
          responseType: 'blob',
        })
        FileSaver.saveAs(
          new Blob([data]),
          `export_allowances_${this.formattedDate}.xlsx`
        )
      } catch ({ response }) {
        if (response && response.status === 422) {
          let data = JSON.parse(await response.data.text())

          if (data && data.message) {
            this.error(data.message)
          }
        }

        this.validateFromResponse(response, false)
      }

      this.downloading = false
    },

    async importEmploymentAllowances() {
      this.uploading = true

      try {
        const formData = new FormData()
        formData.append('company_id', this.activeCompany.id)
        formData.append('file', this.$refs.uploadInput.files[0])

        await this.$http.post('import-employment-allowances', formData, {
          'Content-Type': 'multipart/form-data',
        })

        this.success(
          'Your upload has been submitted, you will receive a notification when it is completed.'
        )
      } catch ({ response }) {
        this.validateFromResponse(response)
      }

      this.$nextTick(() => {
        this.$refs.uploadInput.value = ''
      })

      this.uploading = false
    },

    async fetchCalendars() {
      try {
        const { data } = await this.$http.get('calendars', {
          params: this.$route.query,
        })

        this.calendars = data.reverse()
      } catch ({ response }) {
        this.validateFromResponse(response, false)
      }
    },
  },
}
</script>
